<template>
    <div class="stu-info-table">
        <div class="table-div">
            <el-button type="primary" style="margin-bottom: 14px;" @click="add">添加</el-button>

            <el-table style="width: 100%;" :data="tableData" stripe fit>
                <el-table-column
                    v-for="(item, index) in tableHeader"
                    :key="index"
                    :prop="item.field"
                    :label="item.label"
                    align="center"
                    :min-width="item.width"
                    :show-overflow-tooltip="['upload', 'miupload'].includes(item.type) ? false : true">
                    <template v-if="scope.row[item.field]" slot-scope="scope">
                        <!-- <el-popover v-if="item.type == 'upload'" trigger="hover" :width="imgPopperWidth" placement="right-start" :offset="-12" popper-class="stu-info-table-img-popper">
                            <img :src="scope.row[item.field]" alt="">
                            <img slot="reference" :style="getImgStyle()" :src="scope.row[item.field]" alt="">
                        </el-popover> -->
                        <el-image v-if="item.type == 'upload'" :style="getImgStyle()" :src="scope.row[item.field]" :preview-src-list="[scope.row[item.field]]"></el-image>
                        <template v-else-if="item.type == 'miupload'">
                            <!-- <el-popover v-for="img in scope.row[item.field].split(',')" :key="img" trigger="hover" :width="imgPopperWidth" placement="right-start" :offset="-12" popper-class="stu-info-table-img-popper">
                                <img :src="img" alt="">
                                <img slot="reference" :style="getImgStyle()" :src="img" alt="" >
                            </el-popover> -->
                            <el-image v-for="img in scope.row[item.field].split(',')" :key="img" :style="getImgStyle()" :src="img" :preview-src-list="scope.row[item.field].split(',')"></el-image>
                        </template>
                        <span v-else>{{ scope.row[item.field] }}</span>
                    </template>
                </el-table-column>

                <el-table-column v-if="mode != 'view'" fixed="right" class="operation" label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="text" style="color: #FF3C3C;" size="small" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <DialogWrapper :dialog-obj="dialogObj" @handleClose="dialogObj.dialogVisible = false">
            <StuInfoForm
                class="table-dialog-form"
                :key="dialogObj.key"
                :mode="mode"
                :school-id="schoolId"
                :stu-basic-info="stuBasicInfo"
                :template-info="templateInfo"
                :comps-info="compsInfo"
                :value-info="valueInfo"
                @submit="submit"
            />
        </DialogWrapper>
    </div>
</template>

<script>
import { compsTableHide } from "./comps.js";
import { DialogWrapper } from 'common-local';
import StuInfoForm from "./Form.vue";

export default {
    name: 'StuInfoTable',
    components: {
        DialogWrapper,
        StuInfoForm
    },
    props: [
        'mode',
        'schoolId',
        'stuBasicInfo',
        'templateInfo'
    ],
    data() {
        return {
            imgWidth: 48,
            maxWidth: 500,
            imgPopperWidth: 134,
            compsInfo: [],
            data: [],
            valueInfo: [],
            tableHeader: [],
            tableData: [],
            dialogObj: {
                title: '添加',
                dialogVisible: false,
                width: '700px',
                key: 0,
            }
        }
    },
    filters: {},
    computed: {},
    watch: {
        /**
         * 监控表格的数据data，自动设置表格宽度
         * @param valArr
         */
        tableData(valArr) {
            let _this = this
            this.tableHeader = this.tableHeader.map(function (value) {
                let arr = valArr.map(x => x[value.field])  // 获取每一列的所有数据
                arr = arr.map(e => {
                    return {
                        type: value.type,
                        val: e
                    }
                })
                arr.push({
                    type: 'input',
                    val: value.label
                }) // 把每列的表头当成值是string的组件也加进去算
                // console.log(valArr, value, arr)
                value.width = _this.getMaxLength(arr) + 30 // 每列内容最大的宽度 + 表格的内间距(依据实际情况而定)
                return value
            })
        }
    },
    created() {
        this.getComps()
    },
    mounted() {},
    methods: {
        getImgStyle() {
            return `width: ${this.imgWidth}px; height: ${this.imgWidth}px; object-fit: cover; display: inline-block;`
        },
        /**
        * 遍历列的所有内容，获取最宽一列的宽度
        * @param arr
        */
        getMaxLength (arr) {
            return arr.reduce((acc, item) => {
                if (item) {
                    let calcLen = this.getWidth(item)
                    if (acc < calcLen) {
                        acc = calcLen
                    }
                }
                return acc
            }, 0)
        },
        /**
         * 使用span标签包裹内容，然后计算span的宽度 width： px
         * @param valArr
         */
        getWidth(item) {
            let width = 0;
            if(item.val) {
                if(['upload', 'miupload'].includes(item.type)) {
                    let imgNum = item.val.split(',').length
                    width = imgNum * this.imgWidth
                }else {
                    let html = document.createElement('span');
                    html.innerText = item.val;
                    html.className = 'getTextWidth';
                    document.querySelector('body').appendChild(html);
                    width = document.querySelector('.getTextWidth').offsetWidth;
                    document.querySelector('.getTextWidth').remove();
                    if(width > this.maxWidth) {
                        width = this.maxWidth;
                    }
                }
            }
            return width;
        },
        // 简单深拷贝
        cloneDeep(data) {
            return JSON.parse(JSON.stringify(data))
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        getComps() {
            this._fet('/school/schoolArchivesDesign/listByCondition', {
                archivesTypeId: this.templateInfo.id
            }).then(res => {
                console.log("[获取档案组件]接口返回：", res)
                this.handleRes(res, () => {
                    this.compsInfo = res.data.data.sort((a, b) => { return a.sort - b.sort })
                    this.tableHeader = this.compsInfo.filter(e => !compsTableHide.includes(e.type)).map(e => {
                        return {
                            field: e.field,
                            label: e.label,
                            type: e.type,
                            rules: e.rules ? e.rules : 'input'
                        }
                    })
                    console.log(this.tableHeader,'this.tableHeader')
                    this.getData()
                })
            })
        },
        getData() {
            console.log(this.mode,'mode')
            this._fet('/school/schoolArchivesData/listByCondition', {
                archivesId: this.templateInfo.id,
                peopleId: this.stuBasicInfo.id,
                schoolId: this.schoolId
            }).then(res => {
                console.log("[获取档案学生填写数据]接口返回：", res.data.data)
                if (this.mode === 'view') {
                    this.desensitization(res.data.data)
                }
                this.handleRes(res, () => {
                    this.data = res.data.data
                    let data = this.cloneDeep(res.data.data)
                    let groupIds = [...new Set(data.map(e => e.groupId))]
                    console.log(groupIds,'groupIds');
                    let tableData = []
                    for(let groupId of groupIds) {
                        let row = {}
                        this.$set(row, 'groupId', groupId)
                        for(let header of this.tableHeader) {
                            let val = data.find(e => e.field == header.field && e.groupId == groupId)?.tableValue
                            if(val) {
                                this.$set(row, header.field, val)
                                if (header.rules == 'phone' && this.mode == 'view') {
                                    this.$set(row, header.field, val.slice(0,3) + '*****' + val.slice(val.length - 3))
                                }
                            }
                        }
                        tableData.push(row)
                    }
                    this.tableData = tableData
                    console.log("表单数据：", tableData)
                })
            })
        },
        desensitization (data) {
            data.forEach((item) => {
                // if (item.archivesPersonType && item.archivesPersonType == '1' && item.tableValue) {
                //     item.tableValue = item.tableValue.slice(0,3) + '*****' + item.tableValue.slice(item.tableValue.length - 3)
                // }
                if (item.supplementValue && item.supplementValue == 'idCardNo' && item.tableValue) {
                    item.tableValue = item.tableValue.slice(0,3) + '***********' + item.tableValue.slice(item.tableValue.length - 4)
                }
            })
        },
        add() {
            this.valueInfo = []
            this.dialogObj.title = "添加"
            this.dialogObj.key++
            this.dialogObj.dialogVisible = true;
        },
        edit(row) {
            console.log("[编辑]row:", row)
            this.valueInfo = this.data.filter(e => e.groupId == row.groupId)
            this.dialogObj.title = "编辑"
            this.dialogObj.key++
            this.dialogObj.dialogVisible = true;
        },
        del(row) {
            console.log("[删除]row:", row)
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 确认
                this._fet('/school/schoolArchivesData/deleteList', {
                    groupId: row.groupId||'',
                    schoolId: this.schoolId,
                    peopleId: this.stuBasicInfo.id,
                    archivesPersonType: this.$route.name==='SummaryTeacher'?'2':'1'
                }).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success('删除成功')
                        this.getData()
                        this.$eventDispatch('getTeacherBaseInfo')
                    })
                })
            }).catch(() => {
                // 取消
            });
        },
        submit() {
            this.getData()
            this.dialogObj.dialogVisible = false
        },
    }
}
</script>

<style lang="scss">
.el-tooltip__popper {
    max-width: 50%;
    font-size: 14px !important;
}

.stu-info-table-img-popper {
    background: transparent;
    border: none;
    box-shadow: none;
    min-width: 0;
    margin-left: 0 !important;
    padding-left: 2px;

    .popper__arrow {
        display: none;
    }

    img {
        width: 100%;
        object-fit: contain;
        display: inline-block;
    }
}
</style>

<style lang="scss" scoped>
.table-div {
    margin: 14px auto 16px;
    width: 100%;

    ::v-deep .el-button--text {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    ::v-deep .el-table {
        .el-table__header-wrapper th.el-table__cell .cell {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #505559;
        }

        .el-table__cell .cell{
            color: #505559;
        }

        .el-table__body-wrapper td.el-table__cell .cell {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2B2F33;
        }

        .el-table__row {
            height: 56px;
        }

        .cell {
            line-height: 1;
        }

        td.el-table__cell, th.el-table__cell.is-leaf {
            border: unset;
        }

        /* 鼠标悬浮行 */
        .el-table__body .el-table__row.hover-row td {
            background-color: #EBEFF5 !important;
        }

        .el-button--text {
            padding: 0;
        }
    }
}
</style>
